
// @ts-ignore
import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CoursePreview from "../components/course-preview";

function CoursesPage({ location, data }) {

  const { allCoursesYaml: { nodes: allCourses } } = data;
  const [activeCourses, setActiveCourses] = useState(allCourses)
  const [tags, setTags] = useState({})
  const [activeTags, setActiveTags] = useState({})
  const [selectedTags, setSelectedTags] = useState({})

  const getAllTags = (courses) => {
    const newTags = {}
      courses.forEach(course => {
        course.tags.forEach(tag => {
          if (!(tag in newTags)) {
            newTags[tag] = { count: 1 }
          } else {
            newTags[tag].count += 1
          }
        });
      });
    return newTags;
  };

  useEffect(() => {
    setTags(getAllTags(activeCourses))
  }, [])

  const handleSetSelectedTag = (clickedTag) => {
    if (clickedTag in selectedTags) {
      setSelectedTags(prevTags => {
        delete prevTags[clickedTag];
        return { ...prevTags };
      });
    } else {
      setSelectedTags(prevTags => {
        if (!(clickedTag in prevTags)) {
          return { ...prevTags, [clickedTag]: { count: 1 } };
        }
        return prevTags;
      });
    }
  }

  useEffect(() => {
    if (!Object.keys(selectedTags).length){
      setActiveCourses(allCourses)
      setActiveTags({})
    } else {
      const newCourses = []
      allCourses.forEach(course => {
        if (checkArray(selectedTags, course.tags)) {
          newCourses.push(course)
        }
      })
      setActiveCourses(newCourses)
    }
  }, [selectedTags])

  useEffect(() => {
    setActiveTags(getAllTags(activeCourses))
  }, [activeCourses])

  function checkArray(activeTags, courseTags) {
    const tags = Object.keys(activeTags)
    for (let i = 0; i < tags.length; i++) {
      if (!courseTags.includes(tags[i])) {
        return false;
      }
    }
    return true;
  }

  // TODO: add carousel/list: https://developers.google.com/search/docs/advanced/structured-data/carousel
  // TODO: add product here: https://developers.google.com/search/docs/advanced/structured-data/product
  // TODO: add event data: https://developers.google.com/search/docs/advanced/structured-data/event
  // TODO: add course data: https://developers.google.com/search/docs/advanced/structured-data/course
  // TODO: remove micro-data, leave only json-ld
  return (
    <Layout location={location}>
      <Seo
        title="IT Training (Online and Offline) Courses with Certifications."
        metaKeywords={["devops", "automation", "cloud", "aws", "docker", "software craftsmanship calendar", "software craftsmanship calendars", "comments", "calendar"]}
        metaDescription="Enroll in DevOps and IT training courses today to learn with impactful and relevant course content designed to help individuals develop the skills."
      />

      <section className="mt-8 mb-20 lg:mt-18">
        <h1>Training Courses</h1>
        <p>Never stop learning. Because life never stops teaching.</p>
          <ul className="mt-5 flex gap-0.5 md:gap-2 flex-wrap">
            {
              Object.entries(tags).map(([tag, entries], key) => {
                return (
                  <li
                    className={"flex-none cursor-pointer border-2 border-gray-100 px-4 py-2 text-xxxs font-bold uppercase duration-200 before:content-['#']" +
                      (tag in selectedTags ? " bg-sky-900 text-white" : " text-gray-500") +
                      (!(tag in activeTags) ? " opacity-50 pointer-events-none" : "")}
                    key={key}
                    onClick={() => handleSetSelectedTag(tag)}
                  >
                    {tag + ((entries.count != 1) ? ' - ' + entries.count : '')}
                  </li>
                );
              })}
          </ul>

          <ol className="mt-24 space-y-13">
            {activeCourses.map((course, courseIndex) => {
              const firstCourse = course.dates && course.dates.length > 0 ?
                course.dates
                  .sort((a, b) => (a.startDate > b.startDate))
                  .find(c => c.status != "sold" && c.startDate > new Date().toISOString())
                : null
                return (
                  <CoursePreview
                    key={courseIndex}
                    course={course}
                    courseIndex={courseIndex}
                    firstCourse={firstCourse}
                  />
                )
            })}
          </ol>
      </section>
    </Layout >
  )
}

export default CoursesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allCoursesYaml(
      sort: { fields: [priority], order: ASC }
      filter: { hidden: { ne: true } }
    ) {
      nodes {
        id
        title
        abstract
        description
        difficulty
        duration
        icon
        slug
        type
        tags
        priority
        top
        backgroundColor
        textColor
        meta {
          keywords
          title
          description
        }
        dates {
          startDate
          endDate
          status
          time
        }
        prices {
          name
          oldPrice
          price
          comment1
          comment2
        }
      }
    }
  }
`